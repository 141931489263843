// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bunny-wild-carrot-js": () => import("./../src/pages/bunny-wild-carrot.js" /* webpackChunkName: "component---src-pages-bunny-wild-carrot-js" */),
  "component---src-pages-en-bunny-wild-carrot-js": () => import("./../src/pages/en/bunny-wild-carrot.js" /* webpackChunkName: "component---src-pages-en-bunny-wild-carrot-js" */),
  "component---src-pages-en-index-js": () => import("./../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-old-js": () => import("./../src/pages/en/old.js" /* webpackChunkName: "component---src-pages-en-old-js" */),
  "component---src-pages-greetings-candide-js": () => import("./../src/pages/greetings/candide.js" /* webpackChunkName: "component---src-pages-greetings-candide-js" */),
  "component---src-pages-greetings-holger-js": () => import("./../src/pages/greetings/holger.js" /* webpackChunkName: "component---src-pages-greetings-holger-js" */),
  "component---src-pages-greetings-mario-js": () => import("./../src/pages/greetings/mario.js" /* webpackChunkName: "component---src-pages-greetings-mario-js" */),
  "component---src-pages-greetings-masha-js": () => import("./../src/pages/greetings/masha.js" /* webpackChunkName: "component---src-pages-greetings-masha-js" */),
  "component---src-pages-greetings-thorsten-js": () => import("./../src/pages/greetings/thorsten.js" /* webpackChunkName: "component---src-pages-greetings-thorsten-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-js": () => import("./../src/pages/old.js" /* webpackChunkName: "component---src-pages-old-js" */)
}

